import { v4 as uuidv4 } from "uuid";

class API {
  private baseUrl: string;
  private headers: any;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.headers = {
      "Content-Type": "application/json",
      authtoken: "Awareness-Hub@7895",
    };
  }

  private async handleResponse<T>(response: Response): Promise<T> {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  }

  async get<T>(endpoint: string): Promise<T> {
    const url = `${this.baseUrl}/${endpoint}`;
    const response = await fetch(url, {
      method: "GET",
      headers: this.headers,
    });
    return this.handleResponse<T>(response);
  }

  async post<T>(endpoint: string, data: any): Promise<T> {
    const url = `${this.baseUrl}/${endpoint}`;
    const response = await fetch(url, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    });
    return this.handleResponse<T>(response);
  }

  async put<T>(endpoint: string, data: any): Promise<T> {
    const url = `${this.baseUrl}/${endpoint}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(data),
    });
    return this.handleResponse<T>(response);
  }

  async delete(endpoint: string): Promise<void> {
    const url = `${this.baseUrl}/${endpoint}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: this.headers,
    });
    if (!response.ok) {
      throw new Error(`Failed to delete data at ${url}`);
    }
  }
}

export const serverRequest = new API(
  "https://hnylearning.com/awarenessHubBackend"
);

export const getUserIdFromURL = () => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const myParam = urlParams.get("pId");
  //   const userId = myParam?.split(":");
  //   if (userId?.length) {
  //     return {
  //       // userId: userId[0],
  //       // projectName: userId[1]
  //       userId: "LSB",
  //       projectName: "screening",
  //     };
  //   }

  return {
    userId: "LSB",
    projectName: "screening",
  };
};

export const APIRequestId = uuidv4();
